<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Categoria de Compliance</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form
      ref="form"
    >
      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="category.title"
            outlined
            dense
            label="Categorias"
            :rules="$rules.required"
            required
            :items="category"
            item-text="title"
            item-value="id"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="loading === true"
          color="primary"
          loading
          @click="submit"
        />
        <v-btn
          v-else
          color="primary"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>

import ComplianceCategoryService from '../../../services/compliance-category.service';

export default {
  name: 'CategoryForm',
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      category: {},
      loading: false,
    };
  },
  created() { if (this.populateWith.id) { this.category = this.populateWith; } },
  methods: {
    close() {
      this.$emit('close-dialog');
    },

    async submit() {
      if (this.$refs.form.validate()) {
        if (this.populateWith.id) {
          try {
            this.loading = true;
            await ComplianceCategoryService.updateCategory(this.category);
            this.$emit('category-edited', { ...this.category });
            this.$toast.success('Categoria editada com sucesso');
            this.loading = false;
            this.close();
          } catch (e) {
            this.$handleHttpError(e);
            this.loading = true;
          }
        } else {
          try {
            this.loading = true;
            await ComplianceCategoryService.createCategory(this.category);
            this.$emit('category-added', { ...this.category });
            this.$toast.success('Categoria cadastrada com sucesso');
            this.loading = false;
          } catch (e) {
            this.$handleHttpError(e);
            this.loading = false;
          }
        }
      } else {
        this.$toast.error('Verifique os campos e tente novamente');
        this.loading = false;
      }
    },
  },
};
</script>
